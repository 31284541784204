'use strict'
const asideMenu = document.querySelector('.burgerMenu');
const rightSideMenu = document.querySelector('#rightSideMenu');
const mobileBurgerMenu = document.querySelector('.mobileBurgerMenu');
const bodyMobileMenu = document.querySelector('.cookieSpace');
const backgroundLayer = document.querySelector('.backgroundLayer');
let headerHeight;

const base = {
    onClickEvent :()=>{
        document.addEventListener("click", e =>{
            window.innerWidth >= 1200 && header.outsideClickCloseMenus(e)
            dooFinder.close(e)
        },false)
    },
    init: () => {
        $(function() {
            base.onClickEvent()
        });
    }

}

const header = {
    item:{},
    height:()=> header.item.offsetHeight,
    setRootStyle:() => {
        document.documentElement.style.setProperty('--header-height', header.height() + "px")
    },
    activeClickOnHover: () => {
        const links = document.querySelectorAll('#megaMenu .mainMenu__leftMenu > .level_0 > .body > .list-unstyled > .hasSubMenu > .link--title ')
        links.forEach(el => {
            el.addEventListener('mouseenter', (el) => {
                if(el.currentTarget.nextElementSibling.classList.contains('active')){
                    //menu is already active
                    return;
                }
                window.timeout = setTimeout(() => {
                    el.target.click()
                }, 0);
            });
            el.addEventListener('mouseleave', () => {
                clearTimeout(window.timeout)
            });
        });
    },
    /**
     * [outsideClickCloseMenus clicking outside the megaMenu close all menus (sideMenu and burgerMenu)]
     */
    outsideClickCloseMenus:(e) => {
            if(!e.target.closest('#megaMenu')){
                if(sideMenu.isActive || burgerMenu.isActive){
                    e.preventDefault()
                    e.stopPropagation()
                    sideMenu.close()
                    burgerMenu.close()
                }
            }
    },
    refresh:()=>{
        header.setRootStyle()
    },
    seeMore:{
        show:(e)=> {
            const smActiveMenu = document.querySelector('#megaMenu .level_1.active >.body')
            if(smActiveMenu !== null){
                let menuHeight = smActiveMenu.offsetHeight //altezza totale del div
                let scrollerHeight = smActiveMenu.scrollHeight //altezza con scroll del div
                if (scrollerHeight > menuHeight) {
                    smActiveMenu.parentNode.closest('.js_sideMenuLevel').classList.add('seeMore--show')
                } else {
                    smActiveMenu.parentNode.closest('.js_sideMenuLevel').classList.remove('seeMore--show')
                }
            }

        },
        hide:()=>{
            const smActiveMenu = document.querySelector('#megaMenu .level_1.active')
            if(smActiveMenu !== null){
                smActiveMenu.classList.remove('seeMore--show')
            }

        }
    },
    init: () => {
        $(function() {
            header.item = document.querySelector('header')
            header.setRootStyle()
            window.addEventListener("resize", function(){
                header.refresh()
            });
            header.item.addEventListener("resize", ()=>header.refresh());
            window.innerWidth >= 1200 && header.activeClickOnHover()
        });
    }
}

const dooFinder = {
    close: (e) => {
            if(!dooFinder.isActive()){
                return
            }
            if (!(e.target.closest('#dffullscreen')) && !(e.target.closest('#searchbar_btn'))) {
                document.querySelector('#dffullscreen [data-role="close"]').click();
            }

    },
    isActive: () => document.querySelector('#dffullscreen') ? document.querySelector('#dffullscreen').hasAttribute('visible') : false
}

const sideMenu = {
    item: "",
    isActive:false,
    open: () => {
        sideMenu.subLevel.close()
        burgerMenu.subLevel.close()
        mobileBurgerMenu.classList.add('active');
        backgroundLayer.classList.add('active')
        sideMenu.isActive = true

        if (window.innerWidth < 1200 && $('#rightSideMenuMobile').length <= 0 && $('#topMenuMobile').length <= 0) {
            let cloneAsideMenu = asideMenu.cloneNode(true)
            cloneAsideMenu.id = "rightSideMenuMobile"
            bodyMobileMenu.appendChild(cloneAsideMenu);

            let cloneTopMenu = topMenu.item.cloneNode(true)
            cloneTopMenu.id = "topMenuMobile"
            cloneTopMenu.classList.remove("d-none", "d-xl-flex", "topMenu")
            bodyMobileMenu.appendChild(cloneTopMenu);

            $('body').addClass('no-scroll');
        }
        if ($('.js-hamburger-menu').hasClass('open')) {
            document.querySelector('.js-hamburger-menu').classList.remove('open');
            $('.menu-close').click();
        } else {
            document.querySelector('.js-hamburger-menu').classList.add('open');
            burgerMenu.isActive= true
        }

    },
    close: () => {
        sideMenu.subLevel.closeAll()
        backgroundLayer.classList.remove('active')
        if (window.innerWidth < 1200) {
            const burgerMenu = document.querySelector('#rightSideMenuMobile');
            if(burgerMenu){
                burgerMenu.remove();
            }
            const topMenu = document.querySelector('#topMenuMobile');
            if(topMenu){
                topMenu.remove();
            }
            document.querySelector('.js-hamburger-menu').classList.remove('open');
            $('body').removeClass('no-scroll');
        }
    },
    lockScreen: () => {
        const bodyEl = document.querySelector('body')
        bodyEl.classList.add('lockScreenScrolling')
    },
    unlockScreen: () => {
        const bodyEl = document.querySelector('body')
        bodyEl.classList.remove('lockScreenScrolling')
    },
    subLevel: {
        items: [],
        open: (event, level) => {
            if (!event) {
                return;
            }
            clearTimeout(window.timeout)
            burgerMenu.close()
           $('#megaMenu .list-gallery').slick("refresh");
            backgroundLayer.classList.add('active')

            if (window.innerWidth < 1200) {
                document.querySelector('#topMenuMobile').classList.add("d-none")
                document.querySelector('#rightSideMenuMobile').classList.add("d-none")
            }

            const ParentEl = event.target.parentNode.closest('.js_sideMenuLevel')
            const activeEl = ParentEl.querySelectorAll('.js_sideMenuLevel')
            if (event.currentTarget.nextElementSibling.classList.contains('active')) {
                sideMenu.subLevel.closeActived(activeEl)
                sideMenu.unlockScreen()
                backgroundLayer.classList.remove('active')
            }
            else {
                sideMenu.lockScreen()
                sideMenu.subLevel.closeActived(activeEl)
                event.currentTarget.nextElementSibling.classList.toggle('active');
            }
            header.seeMore.show()
            sideMenu.isActive = true
        },
        close: (event) => {
            if (!event) {
                return;
            }
            backgroundLayer.classList.remove('active')
            const closeEl = event.target.parentNode.closest('.js_sideMenuLevel.active')
            const ParentEl = closeEl.parentNode.closest('.js_sideMenuLevel')
            header.seeMore.hide()
            if (ParentEl) {
                ParentEl.classList.add('active')
                closeEl.classList.remove('active')
                if (window.innerWidth < 1200) {
                    if(ParentEl.classList.contains("level_0")){
                        document.querySelector('#topMenuMobile')?.classList.remove("d-none")
                        document.querySelector('#rightSideMenuMobile')?.classList.remove("d-none")
                    }
                }
            }
        },
        closeAll: () => {
            header.seeMore.hide()
            sideMenu.subLevel.items.forEach(m => {
                m.classList.contains('active') ? m.classList.remove('active') : null;
            })
            backgroundLayer.classList.remove('active')
            sideMenu.isActive = false
        },
        closeActived: (activeEls) => {
            activeEls.forEach(m => {
                m.classList.contains('active') ? m.classList.remove('active') : null;
            })
        }
    },
    init: () => {
        $(document).ready(function () {
            //sideMenu.item = mobileBurgerMenu
            sideMenu.subLevel.items.push(...(document.querySelectorAll('.js_sideMenuLevel')))
        });
    }
}

const burgerMenu = {
    item: "",
    isActive:false,
    open: () => {
        sideMenu.close()
        sideMenu.subLevel.close()
        burgerMenu.isActive = !burgerMenu.isActive

    },
    close: () => {
        manageHeaderLayers.closeLayers()
        burgerMenu.isActive = false
        return;
    },
    subLevel: {
        items: [],
        open: (event, level) => {
            if (!event) {
                return;
            }
            if (event.currentTarget.nextElementSibling.classList.contains('active')) {
                sideMenu.unlockScreen();
                event.currentTarget.nextElementSibling.classList.toggle('active');
                backgroundLayer.classList.remove('active');
            }
            else {
                sideMenu.lockScreen()
                event.currentTarget.nextElementSibling.classList.toggle('active');
                event.currentTarget.classList.toggle('active');

            }
            burgerMenu.isActive = true
        },
        close: (event) => {
            if (!event) {
                return;
            }
            backgroundLayer.classList.remove('active')
            const closeEl = event.target.parentNode.closest('.js_sideMenuLevel.active')
            const ParentEl = closeEl.parentNode.closest('.js_sideMenuLevel')
            if (ParentEl) {
                ParentEl.classList.add('active')
                closeEl.classList.remove('active')
            }
        },
        closeAll: () => {
            sideMenu.subLevel.items.forEach(m => {
                m.classList.contains('active') ? m.classList.remove('active') : null;
            })
            backgroundLayer.classList.remove('active')

        },
        closeActived: (activeEls) => {
            activeEls.forEach(m => {
                m.classList.contains('active') ? m.classList.remove('active') : null;
            })
        }
    },

    init: () => {
        $(document).ready(function () {
            burgerMenu.item = document.querySelector('#js-burger-menu')
            burgerMenu.subLevel.items.push(...(document.querySelectorAll('.js_sideMenuLevel')))
        });
    }
}

const topMenu = {
    item:"",
    cloneOnMobile : ()=>{
        if (mobileBurgerMenu.querySelector('.topMenu')){
            mobileBurgerMenu.querySelector('.topMenu').remove()
            return;
        }

        if (window.innerWidth < 1200) {
            mobileBurgerMenu.appendChild(topMenu.item.cloneNode(true));
        }
    },
    refresh :()=>{
        if (window.innerWidth < 1200) {
            mobileBurgerMenu.appendChild(topMenu.item.cloneNode(true));
        }
    },
    init:()=>{
        $(document).ready(function () {
            topMenu.item = document.querySelector('.topMenu')
            //topMenu.cloneOnMobile()
        });

    }
}
$(function() {
    base.init();
    header.init();
    topMenu.init();
    sideMenu.init();
    burgerMenu.init();
});